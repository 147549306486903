<template>
	<div class="contactPhoneForm" style="">
		<p class="caption">{{ $t("contactPhoneForm.title1") }}</p>
		<div class="iWrp">
			<phone-input
				:placeholder="$t('contactPhoneForm.placeholder')"
				@change="onPhoneChange"
				propname="phone"
				:validate="$v.phone"
				:showError="showError"
			></phone-input>
			<button @click="sendForm">{{ $t("contactPhoneForm.sendBtn") }}</button>
		</div>
		<div class="closeWrp">
			<span class="ic-close-rounded" @click="onClose"></span>
		</div>
		<div class="successWindow" :class="{ active: successInfo }">
			<img :src="require('@/assets/img/Group 876.svg')" alt="123" />
			<div class="textH">{{ $t("contactPhoneForm.successTitle") }}</div>
			<div class="sub">
				<div>{{ $t("contactPhoneForm.successSub1") }}</div>
				<div>{{ $t("contactPhoneForm.successSub2") }}</div>
			</div>
			<div class="closeWrp">
				<span class="ic-close-rounded" @click="$emit('formclose')"></span>
			</div>
		</div>
	</div>
</template>

<script>
import PhoneInput from "@/components/common/inputs/PhoneInput.vue";
import { required, minLength } from "vuelidate/lib/validators";
export default {
	name: "ContactPhoneFormBtn",
	components: {
		PhoneInput,
	},
	data() {
		return {
			successInfo: false,
			formSending: false,
			showError: false,
			phone: "",

			phoneLength: 0,
		};
	},
	methods: {
		onPhoneChange(e) {
			this[e.name] = e.value;
			this.phoneLength = e.length;
		},
		onClose() {
			this.$emit("formclose");
			setTimeout(() => {
				this.successInfo = false;
			}, 1500);
		},
		sendForm() {
			this.showError = true;
			if (!this.formSending && !this.$v.$invalid) {
				this.formSending = true;
				this.axios
					.post(`/api/request/callback`, {
						phone: this.phone,
						source: document.title,
						agent_id: localStorage.getItem("agentId") || "",
					})
					.then(() => {
						this.sendBtnState = "success";
						this.successInfo = true;
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
							event: "FormSend",
							"event-category": "Передзвоніть мені",
							"event-action": "",
						});
					})
					.catch((e) => {
						alert("error! see console");
						this.formSending = false;
						console.log(e);
					});
			}
		},
	},
	validations() {
		return {
			phone: {
				required,
				minLength: minLength(this.phoneLength),
			},
		};
	},
};
</script>

<style lang="scss">
.contactPhoneForm {
	overflow: hidden;
	position: fixed;
	z-index: 55;
	right: 0;
	bottom: 200px;
	border-bottom-left-radius: 24px;
	padding: 24px 16px 0;
	background-color: #fff;
	max-width: 450px;
	filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.08));
	.iWrp {
		display: flex;
		align-items: flex-start;
		@media (max-width: 425px) {
			flex-direction: column;
		}
		.txtInp {
			font-weight: 600;
			font-size: 16px;
			line-height: 120%;
			letter-spacing: -0.02em;
			color: #000000;
			padding: 10px;
			box-shadow: 0 0 0 2px inset #f4a700;
			flex-grow: 1;
			border: none;
			outline: none;
			&:focus {
				box-shadow: 0 0 0 2px inset #d1d1d6;
			}
		}
		button {
			margin-top: 16px;
			height: 64px;
			margin-left: 8px;
			font-weight: 600;
			font-size: 13px;
			line-height: 16px;
			text-align: center;
			color: #000000;
			min-width: 163px;
			background: #f4a700;
			border-radius: 2px;
			border: none;
			transition: 0.3s ease;
			cursor: pointer;
			outline: none;
			&:hover {
				background: #f39526;
			}
			@media (max-width: 425px) {
				margin-left: 0;
				margin-top: 8px;
				height: 40px;
			}
		}
	}
	.closeWrp {
		display: flex;
		justify-content: center;
		padding: 10px 0;
		.ic-close-rounded {
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
	}
	.successWindow {
		pointer-events: none;
		transform: translate(0, -20px);
		opacity: 0;
		background-color: #fff;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		transition: 0.3s ease;
		img {
			margin-bottom: 16px;
		}
		.textH {
			font-weight: 600;
			font-size: 19px;
			line-height: 100%;
			text-align: center;
			color: #f4a700;
			margin-bottom: 10px;
		}
		.sub {
			text-align: center;
			div {
				font-weight: 600;
				font-size: 14px;
				line-height: 160%;
				letter-spacing: -0.02em;
				color: #262628;
			}
		}
		.closeWrp {
			position: absolute;
			bottom: 0;
		}
		&.active {
			opacity: 1;
			transform: translate(0, 0);
			pointer-events: normal;
		}
	}
}
</style>